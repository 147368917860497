import React from "react"
import { Typography, Breadcrumb} from 'antd';
import MainLayout from "../components/layout/MainLayout";
import SEO from "../components/seo";

const { Title } = Typography;


export default () => {

    let breadcrumbItems = [(<Breadcrumb.Item key="discord">Discord</Breadcrumb.Item>)]
    return (
        <MainLayout breadcrumbItems={breadcrumbItems}>
            <SEO title={`Join our Discord Server`} description={`Join the official MrLTactics.com Discord server.`} />
            <Title>Join our Discord Server</Title>
            <iframe title="discord" src="https://discordapp.com/widget?id=379757460128530433&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0"></iframe>

        </MainLayout>
    )
};
